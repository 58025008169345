/* eslint-disable no-constant-condition */
import React from 'react';
import {
  Typography,
  Grid,
  Link
  , TextField, Button
} from '@material-ui/core';
import formModels from '../FormModels/formModels';
import {
  GOVERNMENT_ID_TYPE,
  OCCUPATION_TYPE,
  MEDICARE_CARD_COLOUR,
  CMA_ACCOUNT_PURPOSE,
  CMA_SOURCE_OF_FUNDS
} from '@Common/constants'
import { CheckboxField, InputField, PasswordField } from '@Components/CustomFields'
import moment from 'moment'
import dataStorage from '@s/dataStorage';
import ResendEmail from '@Components/ResendEmail'
import Confirmation from '@Components/Confirmation'
import useStyles from '../../styles';
import {
  getFullAddress,
  getEnv,
  checkKycVerify,
  isBroker
} from '@s/helper/utils'

const { formField } = formModels;

function FullApplicationDetailsReview(props) {
  const classes = useStyles()
  const { formValues } = props;

  const rennderApplicantDetails = (index) => {
    const mobilePhone = formValues?.applicant_details[index]?.applicant_mobile_phone?.value || `04${formValues.applicant_details[index]?.applicant_mobile_phone}`
    let expireDateFormat = 'MM/YYYY'
    const cardColour = formValues.applicant_details[index].government_id?.medicare_card_colour?.value || formValues.applicant_details[index].government_id?.medicare_card_colour;
    const checkCondition = isBroker() || index === 0
    if ([MEDICARE_CARD_COLOUR.BLUE, MEDICARE_CARD_COLOUR.YELLOW].includes(cardColour)) {
      expireDateFormat = 'DD/MM/YY'
    }
    const { title = {}, first_name: firstName, last_name: lastName } = formValues.applicant_details[index]
    const titleText = (title.label || title) ? (title.label || title) + ' ' : ''
    const name = (firstName && lastName) ? `${titleText}${firstName} ${lastName}` : `Applicant ${index + 1}`;
    return <React.Fragment key={`review_applicant_details_${index}`}>
      {
        formValues.applicant_details.length > 1
          ? <Grid item xs={12} sm={12} style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" >
              {name}
            </Typography>
            {checkKycVerify(formValues.applicant_details[index]?.ekyc_overall_status) || !index
              ? <img src={'/company/verified-icon-21@2x.png'} style={{ width: 21, height: 21, marginLeft: 8 }} alt='' />
              : null
            }
          </Grid>
          : null
      }
      {checkCondition
        ? <>
          <Grid item xs={12} sm={6}>
            <TextField
              inputProps={{ readOnly: true }}
              variant="standard"
              label='Full Name'
              value={formValues.applicant_details[index].title.label + ' ' + formValues.applicant_details[index].first_name + ' ' + formValues.applicant_details[index].last_name}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              inputProps={{ readOnly: true }}
              variant="standard"
              label={formField.applicant_details.applicant_email.label}
              value={formValues.applicant_details[index].applicant_email}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              inputProps={{ readOnly: true }}
              variant="standard"
              label={formField.applicant_details.government_id.type.label}
              value={formValues.applicant_details[index].government_id.type.label}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              inputProps={{ readOnly: true }}
              variant="standard"
              label='No.'
              value={formValues.applicant_details[index].government_id.number}
              fullWidth
            />
          </Grid>
          {/* Using Driver License */}
          {formValues.applicant_details[index].government_id.type?.value === GOVERNMENT_ID_TYPE.DRIVER_LICENSE
            ? (
              <React.Fragment>
                <Grid item xs={12} sm={6}>
                  <TextField
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    label={formField.applicant_details.government_id.state_of_issue.label}
                    value={formValues.applicant_details[index].government_id.state_of_issue.label}
                    fullWidth
                  />
                </Grid>
              </React.Fragment>
            )
            : null}
          <Grid item xs={12} sm={6}>
            <TextField
              inputProps={{ readOnly: true }}
              variant="standard"
              label={formField.applicant_details.nationality.label}
              value={formValues.applicant_details[index]?.nationality?.label}
              fullWidth
            />
          </Grid>
          {/* Using Medicare Card */}
          {formValues.applicant_details[index].government_id.type?.value === GOVERNMENT_ID_TYPE.MEDICARE_CARD
            ? (
              <React.Fragment>
                <Grid item xs={12} sm={6}>
                  <TextField
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    label='Type'
                    value={formValues.applicant_details[index].government_id.medicare_card_colour.label + ' / Person No. ' + formValues.applicant_details[index].government_id.medicare_individual_reference_number.label}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    label={formField.applicant_details.government_id.medicare_card_expiry_date.label}
                    value={moment(formValues.applicant_details[index].government_id.medicare_card_expiry_date).format(expireDateFormat)}
                    fullWidth
                  />
                </Grid>
              </React.Fragment>
            )
            : null}
          <Grid item xs={12} sm={6}>
            <TextField
              inputProps={{ readOnly: true }}
              variant="standard"
              label={formField.applicant_details.residential_address_full_address.label}
              value={getFullAddress(formValues.applicant_details[index], 'residential_address')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              inputProps={{ readOnly: true }}
              variant="standard"
              label={formField.applicant_details.gender.label}
              value={formValues.applicant_details[index].gender.label ? formValues.applicant_details[index].gender.label : ''}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              inputProps={{ readOnly: true }}
              variant="standard"
              label={formField.applicant_details.dob.label}
              value={moment(formValues.applicant_details[index].dob).format('DD/MM/YYYY')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              inputProps={{ readOnly: true }}
              variant="standard"
              value={mobilePhone}
              label={formField.applicant_details.applicant_mobile_phone.label}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              inputProps={{ readOnly: true }}
              variant="standard"
              label={formField.applicant_details.australian_tax_resident.label}
              value={formValues.applicant_details[index].australian_tax_resident ? ('YES / ' + (formValues.applicant_details[index].tfn ? formValues.applicant_details[index].tfn : 'TFN Is Not Provided')) : 'NO'}
              fullWidth
            />
          </Grid>
          {formValues.applicant_details[index].tax_exemption && formValues.applicant_details[index].australian_tax_resident
            ? <Grid item xs={12} sm={6}>
              <TextField
                inputProps={{ readOnly: true }}
                variant="standard"
                label={formField.applicant_details.tax_exemption.label}
                value={formValues.applicant_details[index].tax_exemption ? ('YES / ' + formValues.applicant_details[index].tax_exemption_details.label) : 'NO'}
                fullWidth
              />
            </Grid>
            : null}
          <Grid item xs={12} sm={6}>
            <TextField
              inputProps={{ readOnly: true }}
              variant="standard"
              label={formField.applicant_details.occupation_type.label}
              value={formValues.applicant_details[index].occupation_type.label}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              inputProps={{ readOnly: true }}
              variant="standard"
              label={formField.applicant_details.occupation_category.label}
              value={formValues.applicant_details[index].occupation_category.label}
              fullWidth
            />
          </Grid>
          {
            formValues.applicant_details[index].occupation_type?.value === OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER
              ? <React.Fragment>
                <Grid item xs={12} sm={6}>
                  <TextField
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    label={formField.applicant_details.business_owner_trading_name.label}
                    value={formValues.applicant_details[index].business_owner_trading_name}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    label={formField.applicant_details.abn_acn_registration_number.label}
                    value={formValues.applicant_details[index].abn_acn_registration_number || 'NO'}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    label={formField.applicant_details.business_owner_trading_address_full_address.label}
                    value={formValues.applicant_details[index].business_owner_trading_address_full_address?.full_address}
                    fullWidth
                  />
                </Grid>
              </React.Fragment>
              : <React.Fragment />
          }
          <Grid item xs={12} sm={6}>
            <TextField
              inputProps={{ readOnly: true }}
              variant="standard"
              label={formField.applicant_details.source_of_wealth.label}
              value={formValues.applicant_details[index].source_of_wealth.label}
              fullWidth
            />
          </Grid>
        </>
        : <>
          <Grid item xs={12} sm={6}>
            <TextField
              inputProps={{ readOnly: true }}
              variant="standard"
              label='Full Name'
              value={formValues.applicant_details[index].title.label + ' ' + formValues.applicant_details[index].first_name + ' ' + formValues.applicant_details[index].last_name}
              fullWidth
            />
          </Grid>
          {dataStorage.accountStatus && !formValues.applicant_details[index]?.applicant_agreement
            ? <ResendEmail formField={formField} formValues={formValues} index={index} />
            : <Grid item xs={12} sm={6}>
              <TextField
                inputProps={{ readOnly: true }}
                variant="standard"
                label={formField.applicant_details.applicant_email.label}
                value={formValues.applicant_details[index].applicant_email}
                fullWidth
              />
            </Grid>
          }
          {formValues?.applicant_details[index]?.gender?.label
            ? <>
              <Grid item xs={12} sm={6}>
                <TextField
                  inputProps={{ readOnly: true }}
                  variant="standard"
                  label={formField.applicant_details?.gender?.label}
                  value={formValues?.applicant_details[index]?.gender?.label ? formValues.applicant_details[index]?.gender?.label : ''}
                  fullWidth
                />
              </Grid></>
            : <></>}
          {formValues?.applicant_details[index]?.applicant_mobile_phone?.value
            ? <Grid item xs={12} sm={6}>
              <TextField
                inputProps={{ readOnly: true }}
                variant="standard"
                value={mobilePhone}
                label={formField.applicant_details.applicant_mobile_phone.label}
                fullWidth
              />
            </Grid>
            : null}
          {formValues.applicant_details[index]?.dob
            ? <Grid item xs={12} sm={6}>
              <TextField
                inputProps={{ readOnly: true }}
                variant="standard"
                label={formField.applicant_details.dob.label}
                value={moment(formValues.applicant_details[index].dob).format('DD/MM/YYYY')}
                fullWidth
              />
            </Grid>
            : null}

        </>}
    </React.Fragment>
  }
  const getApplicantOther = () => {
    const checkKYC = { KYCTrue: [], KYCFalse: [] }
    const sortApplicant = formValues.applicant_details.slice(1)
    sortApplicant.forEach((current, index) => {
      if (current.ekyc_aml_consent) return checkKYC.KYCTrue.push({ indexApplicant: index + 1, data: current })
      return checkKYC.KYCFalse.push({ indexApplicant: index + 1, data: current });
    })
    return checkKYC
  }

  const renderNewMacquarieCMABankAccount = () => {
    return (
      <React.Fragment>
        {
          !formValues.use_existing_CMT_acc
            ? (
              <React.Fragment>
                <Grid item xs={12} sm={6}>
                  <TextField
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    label={formField.cma_source_of_funds.label}
                    value={formValues.cma_source_of_funds?.value === CMA_SOURCE_OF_FUNDS[getEnv()].OTHER ? `Other - ${formValues.cma_source_of_funds_desc}` : formValues.cma_source_of_funds?.label}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    label={formField.cma_account_purpose.label}
                    value={formValues.cma_account_purpose?.value === CMA_ACCOUNT_PURPOSE[getEnv()].OTHER ? `Other - ${formValues.cma_account_purpose_desc}` : formValues.cma_account_purpose?.label}
                    fullWidth
                  />
                </Grid>
              </React.Fragment>
            )
            : null}
        {formValues.use_existing_CMT_acc
          ? (
            <React.Fragment>
              <Grid item xs={12} sm={6}>
                <TextField
                  inputProps={{ readOnly: true }}
                  variant="standard"
                  label={formField.bank_account_name.label}
                  value={formValues.bank_account_name}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  inputProps={{ readOnly: true }}
                  variant="standard"
                  label={formField.bank_bsb.label + ' - ' + formField.bank_account_number.label}
                  value={formValues.bank_bsb.value + ' - ' + formValues.bank_account_number}
                  fullWidth
                />
              </Grid>
            </React.Fragment>
          )
          : null}
        <Grid item xs={12} sm={6}>
          <TextField
            required
            inputProps={{ readOnly: true }}
            variant="standard"
            label={'Settlement Bank Account'}
            value={'New Macquarie Cma Bank Account'}
            fullWidth
          />
        </Grid>
      </React.Fragment>
    )
  }

  const renderSettlementInformation = () => {
    return (
      <React.Fragment>
        <Grid item xs={12} sm={12}>
          <Typography style={{ fontWeight: 'bold' }}>
            SETTLEMENT INFORMATION
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            inputProps={{ readOnly: true }}
            variant="standard"
            label={'HIN Options'}
            value={formValues.settlement_method ? 'Transfer an existing HIN' : 'Establish a new HIN'}
            fullWidth
          />
        </Grid>
        {
          formValues.settlement_method
            ? (
              <React.Fragment>
                <Grid item xs={12} sm={6}>
                  <TextField
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    label={formField.settlement_existing_hin.label}
                    value={formValues.settlement_existing_hin}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    label={formField.settlement_pid.label}
                    value={formValues.settlement_pid.label}
                    fullWidth
                  />
                </Grid>
              </React.Fragment>
            )
            : null
        }
      </React.Fragment>
    )
  }

  const renderPassword = () => {
    if (dataStorage.accountStatus) return <React.Fragment />
    return (
      <>
        <Grid item xs={12}>
          <Typography variant="button" gutterBottom>
            CREATE PASSWORD
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <PasswordField required name={formField.password.name} label={formField.password.label} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PasswordField required name={formField.confirmPassword.name} label={formField.confirmPassword.label} />
        </Grid>
      </>
    )
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant='h6' style={{ fontWeight: 'bold', marginTop: 16 }}>APPLICATION SUMMARY</Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography style={{ fontWeight: 'bold' }}>
          APPLICANT DETAILS
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          inputProps={{ readOnly: true }}
          variant="standard"
          label={formField.account_type.label}
          value={formValues.account_type}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}></Grid>
      {rennderApplicantDetails(0)}
      {
        isBroker()
          ? <>
            {getApplicantOther().KYCTrue.map(current => rennderApplicantDetails(current.indexApplicant))}
            {getApplicantOther().KYCFalse.map(current => rennderApplicantDetails(current.indexApplicant))}
          </>
          : <React.Fragment />
      }
      {renderSettlementInformation()}
      {renderNewMacquarieCMABankAccount()}
      {renderPassword()}
      <Confirmation index={0} formValues={formValues} />
      {
        isBroker()
          ? <React.Fragment />
          : <>
            {getApplicantOther().KYCTrue.map(current => rennderApplicantDetails(current.indexApplicant))}
            {getApplicantOther().KYCFalse.map(current => rennderApplicantDetails(current.indexApplicant))}
          </>
      }
    </Grid>
  );
}

export default FullApplicationDetailsReview;
