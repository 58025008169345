/* eslint-disable camelcase */
/* eslint-disable multiline-ternary */
import React, { forwardRef, useImperativeHandle } from 'react';
import { Grid, Typography, Button, Link, CircularProgress } from '@material-ui/core';
import { useFormikContext, FieldArray } from 'formik';
import {
  InputField,
  CheckboxField,
  SelectField,
  DatePickerField,
  AutocompleteField,
  AutocompleteFieldAsync,
  PhoneField,
  PhoneNumberField
} from '@Components/CustomFields';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DeleteIcon from '@material-ui/icons/Delete';
import RadioField from '@Components/CustomFields/RadioField';
import {
  AUSTRALIAN_TAX_OPTIONS,
  ACCOUNT_TYPE,
  TITLE_OPTIONS,
  GENDER_OPTIONS,
  GOVERNMENT_ID_TYPE,
  GOVERNMENT_ID_TYPE_OPTIONS,
  STATE_OF_ISSUE_OPTIONS,
  MEDICARE_CARD_COLOUR_OPTIONS,
  MEDICARE_CARD_COLOUR,
  MEDICARE_CARD_INDIDUAL_NUMBERS,
  STREET_TYPE,
  OCCUPATION_TYPE,
  OCCUPATION_TYPE_OPTIONS,
  OCCUPATION_CATEGORY_OPTIONS,
  SOURCE_OF_WEALTH_OPTIONS,
  TAX_EXEMPTION_DETAILS_OPTIONS,
  DEFAULT_APPLICANT
} from '@Common/constants'
import moment from 'moment'
import {
  getSubmitPrimaryApplicantUrl,
  getOpeningAccountUrl,
  getSubmitOtherApplicantUrl
} from '@s/api/index'
import { postData, getData, putData } from '@s/api/request'
import {
  saveDraft,
  getAddressData,
  getMobilePhoneValue,
  isMyApplicant,
  mapDataAddress,
  mapSameAddress,
  mapManualAddress,
  clone,
  getEnv,
  checkKycVerify,
  isBroker,
  isOtherApplicant
} from '@s/helper/utils'
import COUNTRIES from '@Common/countries'
import dataStorage from '@s/dataStorage';
import BrokerData from './BrokerData'
import { makeStyles } from '@material-ui/core/styles';
import DeleteApplicationButton from '@Components/DeleteApplicationButton'
import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'react-native-crypto-js';
import formModel from '../FormModels/formModels';

const { formField } = formModel

const useStyle = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: 10,
    overflow: 'hidden',
    minHeight: '400px',
    display: 'flex',
    // padding: theme.spacing(2),
    [theme.breakpoints.up(808 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6)
      // padding: theme.spacing(3)
    },
    backgroundColor: theme.palette.background.paper
  },
  SelectionBox: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: 'transparent'
  },
  cardBox: {
    width: '33%',
    minHeight: '200px',
    padding: theme.spacing(0),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  cardBox2: {
    width: '33%',
    minHeight: '200px',
    padding: theme.spacing(0),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    backgroundSize: 'cover'
  },
  cardBox3: {
    width: '33%',
    minHeight: '200px',
    padding: theme.spacing(0),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    backgroundSize: 'cover'
  },
  media: {
    marginTop: '15%',
    // height: '60%',
    marginLeft: '30%',
    width: '40% !important'
  },
  buttons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: 16
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  wrapper: {
    margin: theme.spacing(0),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute'
  },
  deleteIcon: {
    display: 'none'
  },
  listItem: {
    '&:hover $deleteIcon': {
      display: 'block'
    },
    '& .MuiListItemIcon-root': {
      minWidth: 0
    }
  },
  leftButtons: {
    display: 'flex',
    flexDirection: 'row',
    '& $wrapper': {
      '& button': {
        marginRight: 16
      },
      '&:first-child': {
        marginLeft: 0,
        '& button': {
          marginLeft: 0
        }
      }
    }
  },
  rightButtons: {
    display: 'flex',
    flexDirection: 'row'
  }
}));

function ApplicationForm(props, ref) {
  const classes = useStyle();
  const { values: formValues, errors, isSubmitting, setFieldValue, setFieldTouched, setSubmitting, submitForm, setTouched, validateForm, touched } = useFormikContext();
  const [activeIndex, setIndex] = React.useState(Math.min(dataStorage.indexApplicant, formValues.applicant_details.length - 1))
  const checkCondition = isMyApplicant(activeIndex);
  const isVerified = checkKycVerify(formValues.applicant_details?.[activeIndex]?.ekyc_overall_status)

  React.useEffect(() => {
    try {
      const checkErrorBroker = (errors) => {
        return !!(errors.advisor_code || errors.branch_code || errors.organization_code || errors.tradeable_products?.equity)
      }
      const checkNext = () => {
        const errorObj = errors?.applicant_details?.[activeIndex];
        if (!errorObj || Object.keys(errorObj).length === 0) {
          if (activeIndex < formValues.applicant_details.length - 1 && (!dataStorage.isSubApplicant || isBroker())) {
            setTouched({})
            setIndex(activeIndex + 1)
            dataStorage.indexApplicant = activeIndex + 1
            let id
            if (dataStorage.listDraft.length === 1 && dataStorage.userType === 0) {
              id = dataStorage.listDraft[0]?.id
            } else {
              id = dataStorage.dicDraft?.id
            }
            saveDraft({
              formData: {
                ...formValues,
                equix_id: dataStorage.equixId
              },
              step: 0,
              index: dataStorage.activeIndex,
              indexApplicant: dataStorage.indexApplicant,
              id
            })
          } else {
            props.onNext && props.onNext(formValues, { setTouched, setSubmitting })
          }
        }
      }
      if (isSubmitting) {
        if (isVerified) {
          setSubmitting(false)
          return checkNext()
        }
        validateForm().then(async errors => {
          console.log('YOLO errors: ', errors)
          if (errors?.applicant_details?.[activeIndex] || checkErrorBroker(errors)) {
            setTouched({ ...touched, ...errors })
            setSubmitting(false)
          } else {
            if (checkCondition) {
              const getTranform = await tranformParams(formValues);
              let id
              if (dataStorage.listDraft.length === 1 && dataStorage.userType === 0) {
                id = dataStorage.listDraft[0]?.id
              } else {
                id = dataStorage.dicDraft?.id
              }
              const kyc = () => {
                const verificationId = formValues.applicant_details[activeIndex]?.verification_id
                let url = ''
                if (verificationId || dataStorage.isOperatorSupport) {
                  url = getOpeningAccountUrl(`/joint/${dataStorage.equixId}`)
                } else {
                  url = (dataStorage.isSubApplicant || (dataStorage.indexApplicant > 0) ? getSubmitOtherApplicantUrl(dataStorage.equixId) : getSubmitPrimaryApplicantUrl())
                }
                const requestMethod = verificationId || dataStorage.isOperatorSupport ? putData : postData
                if (!verificationId && !dataStorage.isOperatorSupport) getTranform.draft_id = dataStorage.dicDraft?.id
                requestMethod(url, getTranform).then(response => {
                  setSubmitting(false);
                  formValues.applicant_details[activeIndex].applicant_id = getTranform.applicant_id
                  const { ekyc_govid_status, ekyc_overall_status, verification_id } = response.ekyc_status[0];
                  verification_id && (formValues.applicant_details[activeIndex].verification_id = verification_id)
                  response.equix_id && (dataStorage.equixId = response.equix_id)
                  if (checkKycVerify(ekyc_overall_status)) {
                    formValues.applicant_details[activeIndex].ekyc_overall_status = ekyc_overall_status
                    return checkNext();
                  }
                  if (dataStorage.equixId) {
                    saveDraft({
                      formData: {
                        ...formValues,
                        equix_id: dataStorage.equixId
                      },
                      step: 0,
                      index: dataStorage.activeIndex,
                      indexApplicant: dataStorage.indexApplicant,
                      id
                    })
                  }
                  if (ekyc_govid_status === 'EKYC_LOCKED_OUT') {
                    return dataStorage.showError && dataStorage.showError('We’re unable to verify your details, please contact hello@equix.app for support')
                  }
                  if (!checkKycVerify(ekyc_overall_status)) {
                    return dataStorage.showError && dataStorage.showError('We’re unable to verify your details, please make sure your details are correct, Then let Application KYC Edit Detail Page')
                  }
                }
                ).catch(error => {
                  setSubmitting(false)
                  // console.log(error)
                  if (error.ekyc_status && error.ekyc_status[0] && error.ekyc_status[0].message) {
                    dataStorage.showError && dataStorage.showError(error.ekyc_status[0].message)
                  } else {
                    dataStorage.showError && dataStorage.showError(error)
                  }
                })
              }
              if (!id) {
                saveDraft({
                  formData: {
                    ...formValues
                  },
                  step: 0,
                  indexApplicant: dataStorage.indexApplicant,
                  index: dataStorage.activeIndex
                }, kyc)
              } else kyc()
            } else {
              setSubmitting(false)
              checkNext();
            }
          }
        })
      }
    } catch (error) {
      console.error('Applicant details confirm error: ', error)
    }
  }, [isSubmitting, errors.applicant_details, activeIndex, formValues.applicant_details])

  const tranformParams = async (data = {}) => {
    try {
      const { applicant_details } = data;

      const obj = {
        applicant_id: applicant_details[activeIndex]?.applicant_id || uuidv4(),
        ekyc_aml_consent: applicant_details[activeIndex].ekyc_aml_consent,
        title: applicant_details[activeIndex]?.title?.value,
        first_name: applicant_details[activeIndex]?.first_name,
        // middle_name: 'twopass',
        last_name: applicant_details[activeIndex]?.last_name,
        gender: applicant_details[activeIndex]?.gender?.value,
        nationality: applicant_details[activeIndex]?.nationality?.value,
        occupation_type: applicant_details[activeIndex]?.occupation_type?.value,
        occupation_category: applicant_details[activeIndex]?.occupation_category?.value,
        source_of_wealth: applicant_details[activeIndex]?.source_of_wealth?.value,
        australian_tax_resident: applicant_details[activeIndex]?.australian_tax_resident
      }
      if (applicant_details[activeIndex]?.occupation_type?.value === OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER) {
        obj.business_owner_trading_name = applicant_details[activeIndex]?.business_owner_trading_name
        obj.abn_acn_registration_number = applicant_details[activeIndex]?.abn_acn_registration_number
      }
      if (applicant_details[activeIndex]?.australian_tax_resident) {
        obj.tax_exemption = applicant_details[activeIndex]?.tax_exemption
        if (applicant_details[activeIndex]?.tax_exemption) {
          obj.tax_exemption_details = applicant_details[activeIndex]?.tax_exemption_details?.value
        }
        if (applicant_details[activeIndex]?.tfn) {
          obj.tfn = applicant_details[activeIndex]?.tfn
        }
      } else {
        obj.tax_exemption = false
      }
      if (applicant_details[activeIndex]?.dob) obj.dob = moment(applicant_details[activeIndex]?.dob, moment.ISO_8601).format('DD/MM/YYYY')
      const getTypeGoverment = applicant_details[activeIndex]?.government_id?.type?.value;

      switch (getTypeGoverment) {
        case GOVERNMENT_ID_TYPE.DRIVER_LICENSE:
          obj.government_id = [
            {
              type: applicant_details[activeIndex]?.government_id.type?.value,
              number: applicant_details[activeIndex]?.government_id.number,
              state_of_issue: applicant_details[activeIndex]?.government_id.state_of_issue?.value,
              first_name: applicant_details[activeIndex]?.first_name,
              // middle_name: 'twopass',
              last_name: applicant_details[activeIndex]?.last_name
            }
          ]
          break
        case GOVERNMENT_ID_TYPE.MEDICARE_CARD:
          obj.government_id = {
            type: applicant_details[activeIndex]?.government_id.type?.value,
            number: applicant_details[activeIndex]?.government_id.number,
            medicare_name_on_card: applicant_details[activeIndex]?.first_name + ' ' + applicant_details[activeIndex]?.last_name,
            medicare_individual_reference_number: applicant_details[activeIndex]?.government_id.medicare_individual_reference_number?.value,
            medicare_card_colour: applicant_details[activeIndex]?.government_id.medicare_card_colour?.value
          }
          if (applicant_details[activeIndex]?.government_id.medicare_card_colour?.value === MEDICARE_CARD_COLOUR.GREEN) {
            obj.government_id.medicare_card_expiry_date = moment(applicant_details[activeIndex]?.government_id.medicare_card_expiry_date, moment.ISO_8601).format('MM/YYYY')
          } else {
            obj.government_id.medicare_card_expiry_date = moment(applicant_details[activeIndex]?.government_id.medicare_card_expiry_date, moment.ISO_8601).format('DD/MM/YY')
          }
          obj.government_id = [obj.government_id]
          break
        case GOVERNMENT_ID_TYPE.PASSPORT:
          obj.government_id = [
            {
              type: applicant_details[activeIndex]?.government_id.type?.value,
              number: applicant_details[activeIndex]?.government_id.number,
              first_name: applicant_details[activeIndex]?.first_name,
              // middle_name: 'twopass',
              last_name: applicant_details[activeIndex]?.last_name
            }
          ]
          break
        default: break
      }
      obj.relationship_type = 'OWNER'
      obj.residential_address_country = 'AUSTRALIA'
      obj.country_of_birth = 'AUSTRALIA';
      // obj.dob = moment(obj.dob, moment.ISO_8601).format('DD/MM/YYYY')
      obj.same_as_ra = applicant_details[activeIndex]?.same_as_ra;

      obj.applicant_email = applicant_details[activeIndex]?.applicant_email?.trim();
      obj.applicant_mobile_phone = getMobilePhoneValue(applicant_details[activeIndex]?.applicant_mobile_phone);

      // map address
      const listAddress = []
      if (!applicant_details[activeIndex]?.manual_address) {
        listAddress.push(applicant_details[activeIndex]?.residential_address_full_address?.id)
      }
      if (applicant_details[activeIndex]?.occupation_type?.value === OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER) {
        listAddress.push(applicant_details[activeIndex]?.business_owner_trading_address_full_address?.id)
      }
      if (listAddress.length) {
        await getAddressData(listAddress)
      }

      if (applicant_details[activeIndex]?.manual_address) {
        mapManualAddress(obj, applicant_details[activeIndex])
        obj.same_as_ra && mapSameAddress(obj)
      } else if (applicant_details[activeIndex]?.residential_address_full_address?.id) {
        mapDataAddress(obj, applicant_details[activeIndex]?.residential_address_full_address?.id, obj.same_as_ra, 'residential_address')
      }
      if (applicant_details[activeIndex]?.occupation_type?.value === OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER) {
        mapDataAddress(obj, applicant_details[activeIndex]?.business_owner_trading_address_full_address?.id, false, 'business_owner_trading_address')
      }
      return obj
    } catch (error) {
      console.error('tranformParams error: ', error)
    }
  }

  const checkBack = (cb) => {
    if (activeIndex > 0) {
      const index = activeIndex - 1
      setSubmitting(false)
      setIndex(index)
      dataStorage.indexApplicant = index
    } else cb && cb()
  }

  useImperativeHandle(ref, () => ({
    checkBack
  }))

  const getFormatExpireDate = () => {
    const cardColor = formValues.applicant_details[activeIndex]?.government_id?.medicare_card_colour
    if (!cardColor || cardColor.value === MEDICARE_CARD_COLOUR.GREEN) {
      return {
        format: 'MM/yyyy',
        views: ['month', 'year']
      }
    }
    return {
      format: 'dd/MM/yy',
      views: ['date', 'month', 'year']
    }
  }

  const getMatchYear = (year) => {
    if (year == null) return Date.now();

    const ConfigDate = new Date(Date.now());

    ConfigDate.setFullYear(ConfigDate.getFullYear() - year);

    return ConfigDate
  }
  const checkDateFeature = (day) => {
    if (day == null) return Date.now();

    const ConfigDate = new Date(Date.now());

    ConfigDate.setDate(ConfigDate.getDate() + day);

    return ConfigDate
  }

  const renderButtons = (actions) => {
    const { applicant_details: applicants, account_type: accountType } = formValues
    if (accountType !== ACCOUNT_TYPE.JOINT) return <React.Fragment />
    const min = 2
    const max = dataStorage.config.maxApplicant
    const num = applicants.length
    return (
      <Grid item xs={12} style={{ marginTop: 10 }}>
        <Typography variant="button" gutterBottom>
          Choose Applicant
        </Typography>
        <div className={classes.buttons}>
          {/* Back to Draft Button is on left handside */}
          <div className={classes.leftButtons}>
            <div className={classes.wrapper}>
              {
                applicants.map((e, i) => {
                  const checkFullName = applicants[i].first_name && applicants[i].last_name;
                  const isDone = (i === 0 && dataStorage.equixId && activeIndex !== 0) || (e.ekyc_overall_status === 'EKYC_VERIFIED') || e?.applicant_agreement
                  const bg = isDone ? '#e0ebb2' : (i === activeIndex ? '#3f51b5' : '#e0e0e0')
                  const color = isDone ? 'rgba(0, 0, 0, 0.87)' : (i === activeIndex ? '#ffffff' : 'rgba(0, 0, 0, 0.87)')
                  const isDisabled = dataStorage.isSubApplicant && (i !== dataStorage.indexApplicant)
                  return (
                    <Button variant="contained"
                      key={`applicant_button_${i}`}
                      style={{ background: bg, color }}
                      disabled={isDisabled}
                      onClick={() => {
                        // if ((Object.entries(errors).length)) return
                        if (isDisabled || isDone || i === activeIndex) return
                        if (i < activeIndex) {
                          dataStorage.indexApplicant = i
                          setIndex(i)
                          setSubmitting(false)
                        } else setSubmitting(true)
                      }} className={classes.button}>
                      {isDone ? <img src={'/company/verified-icon-21@2x.png'} style={{ width: 21, height: 21, marginRight: 8 }} alt='' /> : ''}
                      {checkFullName ? `${applicants[i].title ? applicants[i].title.value : ''} 
                    ${applicants[i].first_name} ${applicants[i].last_name}` : `Applicant ${i + 1}`}
                    </Button>
                  )
                }
                )
              }
            </div>
          </div>
          {
            dataStorage.isSubApplicant && !dataStorage.isOperatorSupport && !isBroker()
              ? <React.Fragment />
              : <div className={classes.rightButtons}>
                <div className={classes.wrapper}>
                  {
                    num < max
                      ? <Button variant="contained"
                        onClick={() => actions.push({ ...DEFAULT_APPLICANT, applicant_id: uuidv4() })}
                        className={classes.button}
                        color='primary'>
                        <PersonAddIcon onClick={(event) => { }} />
                      </Button>
                      : <React.Fragment />
                  }
                </div>
                <div className={classes.wrapper}>
                  {
                    num > min && activeIndex !== 0 && !isVerified
                      ? <Button
                        variant="contained"
                        onClick={() => {
                          actions.remove(activeIndex)
                          if (activeIndex === formValues.applicant_details.length - 1) {
                            setIndex(activeIndex - 1)
                            dataStorage.indexApplicant = activeIndex - 1
                          }
                        }}
                        className={classes.button}
                        color='secondary'>
                        <DeleteIcon onClick={(event) => { }} />
                      </Button>
                      : <React.Fragment />
                  }
                </div>
              </div>
          }
        </div>
      </Grid>
    )
  }

  const renderVerification = () => {
    return (
      <React.Fragment>
        <Grid item xs={12} sm={6}>
          <SelectField disabled={isVerified} required name={`applicant_details[${activeIndex}].title`} label={formField.applicant_details.title.label} data={TITLE_OPTIONS} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField disabled={isVerified} required name={`applicant_details[${activeIndex}].first_name`} label={formField.applicant_details.first_name.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField disabled={isVerified} required name={`applicant_details[${activeIndex}].last_name`} label={formField.applicant_details.last_name.label} fullWidth />
        </Grid>
        {!(formValues.applicant_details[activeIndex].manual_address)
          ? <React.Fragment>
            <Grid item xs={12} sm={6}>
              <AutocompleteFieldAsync
                required
                disabled={isVerified}
                name={`applicant_details[${activeIndex}].residential_address_full_address`}
                label={formField.applicant_details.residential_address_full_address.label}
                fullWidth />
            </Grid>
          </React.Fragment> : null
        }
        {formValues.applicant_details[activeIndex].manual_address
          ? <React.Fragment>
            <Grid item xs={12} sm={12}>
              <CheckboxField disabled={isVerified} name={`applicant_details[${activeIndex}].manual_address`} label="Cannot find your address? Manual Input" />
            </Grid>
            <Grid item xs={6} sm={3}>
              <InputField disabled={isVerified} name={`applicant_details[${activeIndex}].residential_address_unit_flat_number`} label={formField.applicant_details.residential_address_unit_flat_number.label} fullWidth />
            </Grid>
            <Grid item xs={6} sm={3}>
              <InputField disabled={isVerified} required name={`applicant_details[${activeIndex}].residential_address_street_number`} label={formField.applicant_details.residential_address_street_number.label} fullWidth />
            </Grid>
            <Grid item xs={12} sm={3}>
              <InputField disabled={isVerified} required name={`applicant_details[${activeIndex}].residential_address_street_name`} label={formField.applicant_details.residential_address_street_name.label} fullWidth />
            </Grid>
            <Grid item xs={12} sm={3}>
              <SelectField disabled={isVerified} required name={`applicant_details[${activeIndex}].residential_address_street_type`} label={formField.applicant_details.residential_address_street_type.label} data={STREET_TYPE} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputField disabled={isVerified} required name={`applicant_details[${activeIndex}].residential_address_city_suburb`} label={formField.applicant_details.residential_address_city_suburb.label} fullWidth />
            </Grid>
            <Grid item xs={6} sm={3}>
              <SelectField disabled={isVerified} required name={`applicant_details[${activeIndex}].residential_address_state`} label={formField.applicant_details.residential_address_state.label} data={STATE_OF_ISSUE_OPTIONS} fullWidth />
            </Grid>
            <Grid item xs={6} sm={3}>
              <InputField disabled={isVerified} required name={`applicant_details[${activeIndex}].residential_address_postcode`} label={formField.applicant_details.residential_address_postcode.label} fullWidth />
            </Grid>
          </React.Fragment>
          : <React.Fragment>
            <Grid item xs={12} sm={12}>
              <CheckboxField disabled={isVerified} name={`applicant_details[${activeIndex}].manual_address`} label="Cannot find your address? Manual Input" />
            </Grid>
          </React.Fragment>}
        <Grid item xs={12} sm={12} style={{ padding: 0 }}>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField disabled={isVerified} required name={`applicant_details[${activeIndex}].government_id.type`} label={formField.applicant_details.government_id.type.label} data={GOVERNMENT_ID_TYPE_OPTIONS} fullWidth />
        </Grid>
        {
          formValues.applicant_details[activeIndex].government_id.type
            ? <Grid item xs={12} sm={6}>
              <InputField disabled={isVerified} required name={`applicant_details[${activeIndex}].government_id.number`} label={formField.applicant_details.government_id.number.label[formValues.applicant_details[activeIndex].government_id.type?.value]} fullWidth />
            </Grid>
            : <React.Fragment />
        }
        {/* Using Driver License */}
        {
          formValues.applicant_details[activeIndex].government_id.type?.value === GOVERNMENT_ID_TYPE.DRIVER_LICENSE
            ? <Grid item xs={12} sm={6}>
              <SelectField disabled={isVerified} required name={`applicant_details[${activeIndex}].government_id.state_of_issue`}
                label={formField.applicant_details.government_id.state_of_issue.label} data={STATE_OF_ISSUE_OPTIONS} fullWidth />
            </Grid>
            : null
        }

        {/* Using Medicare Card */}
        {
          formValues.applicant_details[activeIndex].government_id.type?.value === GOVERNMENT_ID_TYPE.MEDICARE_CARD
            ? (
              <React.Fragment>
                <Grid item xs={12} sm={3}>
                  <SelectField disabled={isVerified} required name={`applicant_details[${activeIndex}].government_id.medicare_card_colour`} label={formField.applicant_details.government_id.medicare_card_colour.label} fullWidth data={MEDICARE_CARD_COLOUR_OPTIONS} />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <SelectField disabled={isVerified} required name={`applicant_details[${activeIndex}].government_id.medicare_individual_reference_number`} label={formField.applicant_details.government_id.medicare_individual_reference_number.label} data={MEDICARE_CARD_INDIDUAL_NUMBERS} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePickerField
                    name={`applicant_details[${activeIndex}].government_id.medicare_card_expiry_date`}
                    label={formField.applicant_details.government_id.medicare_card_expiry_date.label}
                    required
                    disabled={isVerified}
                    format={getFormatExpireDate().format}
                    setTouched={setFieldTouched}
                    // views={getFormatExpireDate().views}
                    minDate={checkDateFeature(1)}
                    maxDate={new Date('2050/12/31')}
                    fullWidth
                  />
                </Grid>
              </React.Fragment>
            )
            : null}

        <Grid item xs={12} sm={6}>
          <AutocompleteField disabled={isVerified} required name={`applicant_details[${activeIndex}].nationality`} label={formField.applicant_details.nationality.label} data={COUNTRIES} fullWidth />
        </Grid>
        {
          dataStorage.userType === 0 && activeIndex === 0 && !dataStorage.isSubApplicant
            ? <React.Fragment />
            : <Grid item xs={12} sm={6}>
              <InputField required
                disabled={isVerified || dataStorage.isOperatorSupport || isOtherApplicant()}
                inputProps={{ readOnly: isOtherApplicant() }}
                type='email'
                name={`applicant_details[${activeIndex}].applicant_email`}
                label={formField.applicant_details.applicant_email.label}
                fullWidth />
            </Grid>
        }
      </React.Fragment>
    )
  }

  const renderPersonalInfo = () => {
    return (
      <React.Fragment>
        <Grid item xs={12} sm={12}>
          <RadioField disabled={isVerified} required name={`applicant_details[${activeIndex}].gender`} label={formField.applicant_details.gender.label} data={GENDER_OPTIONS} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePickerField
            disabled={isVerified}
            name={`applicant_details[${activeIndex}].dob`}
            label={formField.applicant_details.dob.label}
            required
            format="dd/MM/yyyy"
            minDate={new Date('1920/1/1')}
            maxDate={getMatchYear(18)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {formValues.applicant_details[activeIndex].applicant_mobile_phone?.value
            ? <PhoneField disabled={isVerified} required name={`applicant_details[${activeIndex}].applicant_mobile_phone`} label={formField.applicant_details.applicant_mobile_phone.label} fullWidth />
            : <PhoneNumberField disabled={isVerified} required name={`applicant_details[${activeIndex}].applicant_mobile_phone`} label={formField.applicant_details.applicant_mobile_phone.label} fullWidth />
          }
        </Grid>
      </React.Fragment>
    )
  }

  const renderFinancialInfo = () => {
    return (
      <React.Fragment>
        <Grid item xs={12} sm={6}>
          <SelectField disabled={isVerified} required name={`applicant_details[${activeIndex}].source_of_wealth`} label={formField.applicant_details.source_of_wealth.label} data={SOURCE_OF_WEALTH_OPTIONS} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField disabled={isVerified} required name={`applicant_details[${activeIndex}].occupation_type`} label={formField.applicant_details.occupation_type.label} data={OCCUPATION_TYPE_OPTIONS} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField disabled={isVerified} required name={`applicant_details[${activeIndex}].occupation_category`} label={formField.applicant_details.occupation_category.label} data={OCCUPATION_CATEGORY_OPTIONS[formValues.applicant_details[activeIndex].occupation_type?.value]} fullWidth />
        </Grid>
        {
          formValues.applicant_details[activeIndex].occupation_type?.value === OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER
            ? <React.Fragment>
              <Grid item xs={12} sm={6}>
                <InputField disabled={isVerified} required name={`applicant_details[${activeIndex}].business_owner_trading_name`} label={formField.applicant_details.business_owner_trading_name.label} fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputField disabled={isVerified} type='number' name={`applicant_details[${activeIndex}].abn_acn_registration_number`} label={formField.applicant_details.abn_acn_registration_number.label} fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <AutocompleteFieldAsync
                  required
                  disabled={isVerified}
                  name={`applicant_details[${activeIndex}].business_owner_trading_address_full_address`}
                  label={formField.applicant_details.business_owner_trading_address_full_address.label}
                  fullWidth />
              </Grid>
            </React.Fragment>
            : <React.Fragment />
        }
        <Grid item xs={12} sm={12} style={{ padding: 0 }}>
        </Grid>
        <Grid item xs={12} sm={6}>
          <RadioField
            required
            isBoolean
            disabled={isVerified}
            style={{ flexDirection: 'row ' }}
            name={`applicant_details[${activeIndex}].australian_tax_resident`}
            label={formField.applicant_details.australian_tax_resident.label}
            data={AUSTRALIAN_TAX_OPTIONS}
            fullWidth />
        </Grid>

        {formValues.applicant_details[activeIndex].australian_tax_resident
          ? (
            <React.Fragment>
              <Grid item xs={12} sm={6}>
                <InputField name={`applicant_details[${activeIndex}].tfn`}
                  disabled={isVerified}
                  label={formField.applicant_details.tfn.label}
                  helperText={'Note: Quotation of your Australian Tax File Number(s) (TFN) is optional'}
                  fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CheckboxField disabled={isVerified} name={`applicant_details[${activeIndex}].tax_exemption`} label={formField.applicant_details.tax_exemption.label} />
              </Grid>
            </React.Fragment>)
          : <React.Fragment />
        }
        {formValues.applicant_details[activeIndex].tax_exemption === true && formValues.applicant_details[activeIndex].australian_tax_resident
          ? <Grid item xs={12} sm={6}>
            <SelectField disabled={isVerified} required name={`applicant_details[${activeIndex}].tax_exemption_details`} label={formField.applicant_details.tax_exemption_details.label} data={TAX_EXEMPTION_DETAILS_OPTIONS} fullWidth />
          </Grid>
          : <React.Fragment />
        }
        {
          checkCondition ? <Grid item xs={12} >
            <CheckboxField disabled={isVerified} name={`applicant_details[${activeIndex}].ekyc_aml_consent`}
              label={
                <div>
                  <span>I agree with </span>
                  <Link className={classes.link} target="_blank" href={`/ekyc-and-aml-consent.pdf?${+new Date()}`}>
                    the eKYC & AML Consent
                  </Link>
                  <span>.</span>
                </div>
              }
            />
          </Grid> : <React.Fragment />}
      </React.Fragment>
    )
  }
  const renderApplicant = () => {
    return (
      <React.Fragment>
        <Grid item xs={12} sm={6}>
          <SelectField disabled={isVerified} required name={`applicant_details[${activeIndex}].title`} label={formField.applicant_details.title.label} data={TITLE_OPTIONS} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField disabled={isVerified} required name={`applicant_details[${activeIndex}].first_name`} label={formField.applicant_details.first_name.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField disabled={isVerified} required name={`applicant_details[${activeIndex}].last_name`} label={formField.applicant_details.last_name.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            disabled={isVerified}
            disabled={dataStorage.userType === 0 && activeIndex === 0}
            type='email' required name={`applicant_details[${activeIndex}].applicant_email`}
            label={formField.applicant_details.applicant_email.label}
            helperText={'We will send a reference email to this email address'}
            fullWidth />
        </Grid>
      </React.Fragment>
    )
  }

  const renderNote = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography variant='h6' style={{ fontWeight: 'bold', marginTop: 16 }}>APPLICANT DETAILS</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body1'>Please complete the following fields. This information will be used to create your trading account, and for the purpose of conducting an Electronic Verification (EV) check which is required by the AML/CTF (Anti-Money Laundering and Counter-Terrorism Financing) Act.</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body2'>* = required field</Typography>
        </Grid>
      </React.Fragment>
    )
  }

  return (
    <Grid container spacing={3}>
      {renderNote()}
      {checkCondition ? <BrokerData disabled={isVerified} setFieldValue={setFieldValue} /> : <React.Fragment />}
      <FieldArray name={formField.applicant_details.name}>
        {({ push, remove }) => {
          return formValues.applicant_details.map((e, i) => {
            if (i !== activeIndex) return <React.Fragment key={`applicant_details_${i}`} />
            return (
              <React.Fragment key={`applicant_details_${i}`}>
                {renderButtons({ push, remove })}
                {checkCondition ? <>
                  {renderVerification()}
                  {renderPersonalInfo()}
                  {renderFinancialInfo()}
                </> : <>
                  {renderApplicant()}
                </>}
              </React.Fragment>
            )
          })
        }}
      </FieldArray>
    </Grid>
  );
}

export default forwardRef(ApplicationForm)
